$hegic-green: #45fff4;
$hegic-dark-blue: #121c36;
$hegic-blue: #19274d;
$hegic-defefe: #defefe;
$hegic-yellow: #ffd24c;
$hegic-red: #f76eb2;

@import url('https://fonts.googleapis.com/css?family=Exo+2&display=swap');
@import url('https://fonts.googleapis.com/css?family=Jura:wght@700');

$font-family-sans-serif: 'Exo 2';

$primary: $hegic-green;
$body-bg: $hegic-dark-blue;
$card-bg: $hegic-blue;
$card-border-color: $hegic-green;
$card-color: $hegic-defefe;

$nav-tabs-link-active-color: $hegic-green;
$nav-tabs-link-active-bg: $hegic-blue;
$nav-tabs-link-active-border-color: $hegic-green;

$input-bg: $hegic-blue;
$input-color: $hegic-defefe;
$input-border-color: $hegic-green;
$input-border-radius: 0;
$input-group-addon-bg: $hegic-blue;
@import "node_modules/bootstrap/scss/bootstrap";

.profit-info-box {
    padding:5px;
    background: #24366b;
    margin:1px;
    width:50%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

// .profit-info-box::before{
//     content: '';
//     height: 100%;
//     width: 5px;
//     position: absolute;
//     left: 0;
// }

// .profit-info-box.wbtc::before{
//     background: #ffd24c;
// }

// .profit-info-box.eth::before{
//     background: #f76eb2;
// }

.input-button, .input-button:hover, .input-button.btn-secondary:hover{
    font-weight: bold;
    font-family: 'Jura';
    letter-spacing: 0.1em;
    background: transparent;
    border-color: #45fff4;
    border-image-slice: 20;
    border-style: solid;
    border-radius: 2px;
    box-sizing: border-box;
    border-image-width: 50px;
}

.main-button {
    margin-top: 10px;
    color: #15203d;
    font-weight: bold;
    font-family: 'Jura';
    letter-spacing: 0.1em;
    background: $hegic-green;
    border-image-slice: 20;
    border-style: solid;
    border-radius: 5px;
    border-image-width: 50px;
}

.btn-secondary.disabled, .btn-secondary:disabled, .btn-secondary:hover {
    color: #15203d;
    border-color: transparent;
}

.input-button.btn-secondary:hover {
    color: #45fff4;
}